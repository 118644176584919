import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Article from '../components/Article'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Article>
      <h1>NOT FOUND</h1>
      <p>Our apologies, the page you are looking for does not exist.</p>
    </Article>
  </Layout>
)

export default NotFoundPage
